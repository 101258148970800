import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMerchantPDF } from '../../redux/merchant-pdf';

export default function MaterialPDF() {
  const merchantPdf = useSelector((state) => state.merchantPdf.data);
  const data = merchantPdf && merchantPdf.materialsPdf.file.url;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMerchantPDF());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data ? (
    <object
      data={data}
      type="application/pdf"
      style={{ width: '100%', height: '100vh' }}
      title="Merchant material PDF"
    />
  ) : (
    <div />
  );
}
